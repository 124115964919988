@import 'bootswatch/dist/united/_variables.scss';
@import 'tippy.js/dist/tippy.css';

html,
body {
    overflow-x: hidden;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.step {
    width: 28px;
    height: 28px;
    line-height: 28px;
    display: block;
    font-size: 14px;
    color: #ffffff;
    border-radius: 50%;
    margin: 0 auto 10px auto;
}
.step {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: block;
    font-size: 10px;
    color: #ffffff;
    border-radius: 50%;
    margin: 0 auto 10px auto;
}

.grecaptcha-badge {
    visibility: hidden;
}

/* Hover state on all cards  */
.card.card-clickable,
table-data .card {
    cursor: pointer;

    &.card-selected,
    &:hover {
        border: 1px solid #2c3e50;
    }
}
.card.card-clickable.card-disabled {
    position: relative;
    opacity: 0.8;
    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    ::after {
        content: '';
        position: absolute;
        cursor: not-allowed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: repeating-linear-gradient(
            135deg,
            rgba(100, 100, 100, 0),
            rgba(100, 100, 100, 0) 10px,
            rgba(100, 100, 100, 0.025) 10px,
            rgba(100, 100, 100, 0.025) 20px
        );
    }
}

.card.card-clickable {
    .card-selected-icon {
        position: absolute;
        top: 3px;
        right: 7px;
    }

    .card-img-top.card-img-top-center {
        width: 50%;
        margin: 25% 25% 10% 25%;
    }
    .media img {
        max-width: 48px;
    }
    .two-lines {
        display: table;
    }
    .two-lines > * {
        line-height: 1.5em;
        height: 3em;
        vertical-align: middle;
        display: table-cell;
        width: 100%;
    }
}

div.card-header,
span.input-group-addon,
a {
    cursor: pointer;
}

input[type='number'] {
    -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.img-xs,
.img-sm,
.img-md,
.img-lg {
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 2px;
    position: relative;
}
.img-xs,
.img-banner-xs {
    height: 32px;
    width: 32px;
}
.img-sm,
.img-banner-sm {
    height: 48px;
    width: 48px;
}
.img-md,
.img-banner-md {
    height: 64px;
    width: 64px;
}
.img-lg,
.img-banner-lg {
    height: 128px;
    width: 128px;
}
.img-xl,
.img-banner-xl {
    height: 256px;
    width: 256px;
}

/* Fix not needed padding for small devices */
@media (max-width: 767px) {
    .grecaptcha-badge {
        display: none !important;
    }
}

/* Fix for full accordion action */
ngb-accordion .card-header {
    position: relative;
}

.card-body .form-group {
    margin-bottom: 7.5px;
    margin-top: 7.5px;
}

.well .table {
    margin-bottom: 0;
}

/* for sm */

.custom-switch.custom-switch-sm .custom-control-label {
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
    padding-left: 3rem;
    padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
    width: calc(2rem - 4px);
    height: calc(2rem - 4px);
    border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
    padding-left: 4rem;
    padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
    height: 2.5rem;
    width: calc(4rem + 0.75rem);
    border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
    width: calc(2.5rem - 4px);
    height: calc(2.5rem - 4px);
    border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(2.5rem - 0.25rem));
}

// BsDateConfig
.bs-datepicker-config-today {
    // background-color: rgba(185, 185, 185, 0.15);
    border: 1px solid rgba(84, 112, 139, 0.9);
    // background: #54708b;
}
